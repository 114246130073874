import {
  Calendar as AntCalendar,
  Badge,
  Button,
  DatePicker,
  Descriptions,
  Modal,
  TimePicker,
  Typography,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import dayjs, { Dayjs } from "dayjs";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { IoMdCalendar } from "react-icons/io";
import { MdCancel, MdEdit, MdSchedule } from "react-icons/md";
import { Navigate } from "react-router-dom";
import { PrivateRoute } from "../components/Route";
import { useRequest } from "../hooks/useRequest";
import { IAppointment } from "../lib/model";
import { useCTX } from "../state";

export default function Calendar() {
  const [date, setDate] = useState<{ from: string; to: string }>();
  const [appointments, setAppointments] = useState<IAppointment[]>([]);
  const [current, setCurrent] = useState(dayjs().format("YYYY-MM-DD"));

  const [noteAppointment, setNoteAppointment] = useState<IAppointment | null>(
    null
  );
  const [note, setNote] = useState("");

  const [cancelAppointment, setCancelAppointment] =
    useState<IAppointment | null>(null);

  const [cancelAllAppointment, setCancelAllAppointment] =
    useState<IAppointment | null>(null);

  const [resAppointment, setResAppointment] = useState<IAppointment | null>(
    null
  );
  const [resArgs, setResArgs] = useState<{
    date: string | null;
    from_time: string | null;
    to_time: string | null;
  }>({
    date: null,
    from_time: null,
    to_time: null,
  });

  const prevRef = useRef<any>({ from: null, to: null });

  const {
    state: { user, settings },
  } = useCTX();
  const [notingRequest, noting] = useRequest();
  const [cancelRequest, cancelling] = useRequest();
  const [cancelAllRequest, cancellingAll] = useRequest();
  const [rescheduleRequest, rescheduling] = useRequest();
  const [listRequest] = useRequest();

  const currentAppointments = useMemo(
    () => appointments.filter((a) => a.date === current),
    [current, appointments]
  );

  const cellRender = useCallback(
    (d: Dayjs) => {
      const _ = appointments.filter((a) => a.date === d.format("YYYY-MM-DD"));
      return (
        <div className="flex flex-col">
          {_.map((item) => (
            <Badge
              key={item.id}
              className="flex items-center"
              status={
                item.status === "A"
                  ? "success"
                  : item.status === "C"
                  ? "error"
                  : "warning"
              }
              text={
                <div
                  style={{ fontSize: "0.7rem" }}
                  className="whitespace-nowrap"
                >
                  {item.from_time} - {item.to_time}
                </div>
              }
            />
          ))}
        </div>
      );
    },
    [appointments]
  );

  const onNote = () => {
    notingRequest(
      "post",
      "calendars/api/addUpdateAppointment/",
      () => {
        setAppointments((_) => {
          const __ = [..._];
          const aidx = _.findIndex((a) => a.id === noteAppointment?.id);
          __[aidx] = { ..._[aidx], notes: note };
          return __;
        });
        setNoteAppointment(null);
      },
      {
        email: user?.email,
        appointmentId: noteAppointment?.id,
        notes: note,
      }
    );
  };

  const onCancel = (operation: "one" | "all") => {
    (operation === "one" ? cancelRequest : cancelAllRequest)(
      "post",
      "calendars/api/cancelAppointment/",
      (res) => {
        setAppointments((_) => {
          const __ = [..._];
          for (const aid of res.data.aids) {
            const aidx = __.findIndex((a) => a.id === aid);
            __[aidx] = { ..._[aidx], status: "C" };
          }
          return __;
        });
        if (operation === "one") setCancelAppointment(null);
        else setCancelAllAppointment(null);
      },
      {
        email: user?.email,
        appointmentId: cancelAppointment?.id,
        operation,
      }
    );
  };

  const onReschedule = () => {
    rescheduleRequest(
      "post",
      "calendars/api/rescheduleAppointment/",
      () => {
        setAppointments((_) => {
          const __ = [..._];
          const aidx = _.findIndex((a) => a.id === resAppointment?.id);
          __[aidx] = { ..._[aidx], status: "A", ...(resArgs as any) };
          return __;
        });
        setResAppointment(null);
      },
      {
        email: user?.email,
        appointmentId: resAppointment?.id,
        ...resArgs,
      }
    );
  };

  useEffect(() => {
    if (date?.from !== prevRef.current.from) {
      listRequest(
        "post",
        "calendars/api/appointmentsList/",

        (res) => {
          setAppointments(res.data.appoint_list);
        },
        {
          email: user?.email,
          providerId: user?.id,
          from_date: date?.from,
          to_date: date?.to,
        }
      );
      prevRef.current = { from: date?.from, to: date?.to };
    }
  }, [date, user, listRequest]);

  if (settings.type !== "provider") {
    return <Navigate to={"/"} />;
  }

  return (
    <PrivateRoute>
      <div className="w-full">
        <Modal
          open={!!noteAppointment}
          title="Notes"
          onCancel={() => setNoteAppointment(null)}
          confirmLoading={noting}
          okText="Save"
          onOk={onNote}
        >
          <TextArea
            rows={5}
            value={note}
            onChange={(e) => setNote(e.target.value)}
            className="my-4"
          />
        </Modal>
        <Modal
          open={!!cancelAppointment}
          title="Cancel Appointment"
          onCancel={() => setCancelAppointment(null)}
          confirmLoading={cancelling}
          onOk={() => onCancel("one")}
        >
          <span>
            Are you sure that you want to cancel "{cancelAppointment?.from_time}{" "}
            - {cancelAppointment?.to_time}" appointment?{" "}
          </span>
        </Modal>
        <Modal
          open={!!cancelAllAppointment}
          title="Cancel All Appointments"
          onCancel={() => setCancelAllAppointment(null)}
          confirmLoading={cancellingAll}
          onOk={() => onCancel("all")}
        >
          <span>
            Are you sure that you want to cancel all appointments henceforth
            belonging to group "{cancelAllAppointment?.group}"?
          </span>
        </Modal>
        <Modal
          open={!!resAppointment}
          title="Reschedule Appointment"
          onCancel={() => setResAppointment(null)}
          confirmLoading={rescheduling}
          onOk={onReschedule}
        >
          <div className="flex flex-col items-start py-8">
            <DatePicker
              disabledDate={(d) => d.isBefore(dayjs())}
              defaultValue={dayjs(resArgs.date)}
              className="mb-4"
              allowClear={false}
              onChange={(d) =>
                setResArgs({ ...resArgs, date: d!.format("YYYY-MM-DD") })
              }
            />
            <TimePicker.RangePicker
              defaultValue={[
                dayjs(resArgs.from_time, "HH:mm:ss"),
                dayjs(resArgs.to_time, "HH:mm:ss"),
              ]}
              allowClear={false}
              onChange={(d) =>
                setResArgs({
                  ...resArgs,
                  from_time: d![0]!.format("HH:mm:ss"),
                  to_time: d![1]!.format("HH:mm:ss"),
                })
              }
            />
          </div>
        </Modal>
        <AntCalendar
          mode="month"
          onPanelChange={(d) =>
            setDate({
              from: d.startOf("month").format("YYYY-MM-DD"),
              to: d.endOf("month").format("YYYY-MM-DD"),
            })
          }
          cellRender={cellRender}
          onChange={(d) => {
            setCurrent(d.format("YYYY-MM-DD"));
          }}
        />
        {currentAppointments.length > 0 && (
          <div className="mb-8">
            <Typography.Title
              level={4}
              className="font-poppins flex items-center pb-4"
            >
              <IoMdCalendar className="mr-2" /> Appointments
            </Typography.Title>
            <div>
              {currentAppointments.map((a) => (
                <Badge.Ribbon
                  key={a.id}
                  text={
                    a.status === "A"
                      ? "Active"
                      : a.status === "C"
                      ? "Cancelled"
                      : a.status === "E"
                      ? "Expired"
                      : ""
                  }
                  color={
                    a.status === "A"
                      ? "green"
                      : a.status === "C"
                      ? "red"
                      : "yellow"
                  }
                  style={{ fontSize: "0.7rem" }}
                >
                  <div className="border border-solid border-gray-200 rounded-lg p-5 mb-4">
                    <Descriptions>
                      <Descriptions.Item label="Time">
                        {a.from_time} - {a.to_time}
                      </Descriptions.Item>
                      <Descriptions.Item label="Customer">
                        {a.customer.name}
                      </Descriptions.Item>
                      <Descriptions.Item label="Address">
                        {a.address.neighborhood}, {a.address.city}
                      </Descriptions.Item>
                      {a.services.length > 0 && (
                        <>
                          <Descriptions.Item label="Order Number">
                            {a.order_number}
                          </Descriptions.Item>
                          <Descriptions.Item label="Services">
                            {a.services}
                          </Descriptions.Item>
                        </>
                      )}
                      {a.short_descr && (
                        <Descriptions.Item label="Comment">
                          {a.short_descr}
                        </Descriptions.Item>
                      )}
                      {a.group && (
                        <Descriptions.Item label="Group">
                          {a.group}
                        </Descriptions.Item>
                      )}
                      <Descriptions.Item
                        label="Notes"
                        className="whitespace-pre-wrap"
                      >
                        {a.notes}
                      </Descriptions.Item>
                    </Descriptions>
                    <div className="flex mt-4 items-center justify-between">
                      <Button
                        className="text-xs"
                        icon={<MdEdit />}
                        onClick={() => {
                          setNote(a.notes);
                          setNoteAppointment(a);
                        }}
                      >
                        Note
                      </Button>
                      <div>
                        <Button
                          className="text-xs mr-2"
                          icon={<MdSchedule />}
                          onClick={() => {
                            setResArgs({
                              date: a.date,
                              from_time: a.from_time,
                              to_time: a.to_time,
                            });
                            setResAppointment(a);
                          }}
                        >
                          Reschedule
                        </Button>
                        {a.status === "A" && (
                          <>
                            <Button
                              className="text-xs mr-2"
                              icon={<MdCancel />}
                              onClick={() => setCancelAppointment(a)}
                            >
                              Cancel
                            </Button>
                            {a.group ? (
                              <Button
                                className="text-xs"
                                icon={<MdCancel />}
                                onClick={() => setCancelAllAppointment(a)}
                              >
                                Cancel All
                              </Button>
                            ) : null}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </Badge.Ribbon>
              ))}
            </div>
          </div>
        )}
      </div>
    </PrivateRoute>
  );
}
